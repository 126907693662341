.loader-container {
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20vh;

  .text {
    margin-top: 25px;
    font-size: 3rem;
    color: rgba(0, 0, 0, 0.5);
    text-align: center;
  }
}
