@import "./../global";

@mixin default_scrollbar {
  ::-webkit-scrollbar {
    width: 3rem;
  }
  ::-webkit-scrollbar-track {
    border-radius: 2rem;
    box-shadow: inset -1rem 0 0 1rem $admin-grey;
    border: solid 1rem transparent;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 2rem;
    box-shadow: inset -1rem 0 0 1rem $admin-primary-blue;
    border: solid 1rem transparent;
    cursor: grab;
  }
}

@mixin default_dropdown {
  @include default_scrollbar;
  .dropdown-menu {
    background-color: $admin-light-grey;
    border-radius: $border-radius-medium;
    background-color: $admin-light-grey;
    display: flex;
    flex-direction: column;
    transition: max-height 0.2s ease, opacity 0.2s ease,
      background-color 0.2s ease, box-shadow 0.2s ease;
    padding: 0.5rem;
    gap: 0.3rem;
    // overflow-x: hidden;
    overflow-y: auto;

    position: absolute;
    top: 100%;
    width: 100%;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    z-index: 10;

    input,
    span {
      font-size: $feature-txt-size;
      font-family: $body-font;
    }

    &.opened {
      max-height: calc(6 * 5.5rem);
      height: auto;
      opacity: 1;
      pointer-events: all;

      .entry {
        opacity: 1;
        transition-delay: 0.1s;
        pointer-events: all;
      }
    }
    &.closed {
      height: auto;
      max-height: calc(1rem);
      transition-delay: 0.1s;
      opacity: 0;
      pointer-events: none;
      .entry {
        opacity: 0;
        pointer-events: none;
      }
    }

    .entry {
      cursor: pointer;
      span {
        padding: 1rem;
        display: flex;
        gap: 1.4rem;
        background-color: $admin-light-grey;
        border-radius: $border-radius-medium;
        width: 100%;
        transition: opacity 0.2s ease, background-color 0.2s ease,
          box-shadow 0.2s ease;
        transition-delay: 0s;
        &:hover {
          background-color: $white;
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
        }
      }
    }
  }
}
