@import "../sizes";

// ########## BREAKPOINTS ##########
@mixin breakpoint-mobile-only {
  @media screen and (min-width: $phone-size) {
    @content;
  }
}
@mixin breakpoint-mobile {
  @media screen and (min-width: $tablet-small-size) {
    @content;
  }
}
@mixin breakpoint-tablet {
  @media screen and (min-width: $tablet-big-size) {
    @content;
  }
}
@mixin breakpoint-laptop {
  @media screen and (min-width: $desktop-size) {
    @content;
  }
}
@mixin breakpoint-desktop {
  @media screen and (min-width: $desktop-size-large) {
    @content;
  }
}
