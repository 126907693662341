@import "./styles/mixins/dropdown-mixins";
@import "./styles/mixins/breakpoints";

*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  min-height: 100vh;

  overflow-y: auto;

  @include breakpoint-mobile {
    overflow-y: scroll;
  }

  @include default_scrollbar;
}

html,
body {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

body {
  font-family: "DM Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  #root {
    flex-grow: 1;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
